import Moralis from "moralis";
import { EvmChain } from "@moralisweb3/common-evm-utils";
import { useEffect, useMemo, useState } from "react";
import { IpfsImage } from "react-ipfs-image";
import {
  Box,
  Heading,
  Image,
  SimpleGrid,
  Spacer,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";

const address = "0x274da56f03727A58b3B684018cb4A930475A8233";
const chain = EvmChain.AVALANCHE;
await Moralis.start({
  apiKey: "rTO3ivS5kbrUYLADjiK07aprt07UIKh9lcdEy346azAzfSGi6zfYLBA4z9C1MuE4",
});
const balance = await Moralis.EvmApi.balance.getNativeBalance({chain:chain, address:address})
const ethbalance = await Moralis.EvmApi.balance.getNativeBalance({address:address})
async function GetNFTs() {
  const response = await Moralis.EvmApi.nft.getWalletNFTs({
    address: address,
    chain: chain,
  });
  const metdata = await Promise.all(response.result.map((e) => e.metadata));
  return metdata;
}
async function GetETHNFTs() {
  const response = await Moralis.EvmApi.nft.getWalletNFTs({
    address: address,
  });
  const metdata = await Promise.all(response.result.map((e) => e.metadata));
  return metdata;
}

export default function Home() {
  const [nfts, setNfts] = useState();
  useMemo(() => {
    GetNFTs().then((e) => {
      GetETHNFTs().then((w) => {
        setNfts(w.concat(e))
      });
    });
    ;
  }, []);


  return (
    <>
      <Box boxSize={'-webkit-max-content'} padding='2' bgAttachment={'fixed'} backgroundImage={'https://images.saymedia-content.com/.image/t_share/MTc4NzM1OTc4MzE0MzQzOTM1/how-to-create-cool-website-backgrounds-the-ultimate-guide.png'}>
        <Heading>Studio Snowforge Treasury</Heading>
        <Box boxSize={'-webkit-max-content'} rounded='2xl' border={'2px'} bgColor={'blackAlpha.200'} padding={'5'} marginBottom={2}>
            <Text>Treasury address: {address} </Text>
            <Text>Avax balance: {Number(balance.result.balance.ether).toFixed(3)} $AVAX</Text>
            <Text>ETH balance: {Number(ethbalance.result.balance.ether).toFixed(3)} $ETH</Text>
        </Box>
        <Box boxSize={'-webkit-max-content'} rounded='2xl' border={'2px'} bgColor={'blackAlpha.200'} padding={'5'}>
          <SimpleGrid minChildWidth='60' spacingY={5}>
            {nfts?.map((e, index) => (
              <Box border={"2px"} boxSize={60} key={index} justifyContent='center' alignContent={'center'}>
                {e?.image.substring(0,4)=="http"?<Image maxHeight={'inherit'} src={e.image}/>:<IpfsImage style={{maxHeight:237}} hash={e.image} />}
                <Text>{e?.name}</Text>
              </Box>
            ))}
          </SimpleGrid>
        </Box>
      </Box>
    </>
  );
}
